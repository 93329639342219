import React, { FC, SVGProps } from 'react'

export const CloseSvg: FC<SVGProps<SVGSVGElement> & { innerFill?: string }> = ({
  innerFill = '#2E2A29',
  ...rest
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.93934 2.93934C3.52513 2.35355 4.47487 2.35355 5.06066 2.93934L21.0607 18.9393C21.6464 19.5251 21.6464 20.4749 21.0607 21.0607C20.4749 21.6464 19.5251 21.6464 18.9393 21.0607L2.93934 5.06066C2.35355 4.47487 2.35355 3.52513 2.93934 2.93934Z"
      fill={innerFill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.93934 21.0607C2.35355 20.4749 2.35355 19.5251 2.93934 18.9393L18.9393 2.93934C19.5251 2.35355 20.4749 2.35355 21.0607 2.93934C21.6464 3.52512 21.6464 4.47487 21.0607 5.06066L5.06066 21.0607C4.47487 21.6464 3.52513 21.6464 2.93934 21.0607Z"
      fill={innerFill}
    />
  </svg>
)
