import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import React, { ComponentPropsWithoutRef, forwardRef } from 'react'

export const Anchor = forwardRef<
  HTMLAnchorElement,
  { black?: boolean } & ComponentPropsWithoutRef<typeof StyledAnchor>
>(({ href, children, black, ...props }, ref) => {
  return (
    <StyledAnchor
      ref={ref}
      className={black ? blackCss : blueCss}
      href={href}
      {...props}
    >
      {children}
    </StyledAnchor>
  )
})

const StyledAnchor = styled.a`
  text-decoration: none;
  cursor: pointer;
`
const blueCss = css`
  color: var(--info-soft, #90aefd);

  &:visited {
    color: var(--info-soft, #90aefd);
  }
`
const blackCss = css`
  color: var(--accent-primary-default, #2e2a29);

  &:visited {
    color: var(--accent-primary-default, #2e2a29);
  }
`
