import { cx, css } from '@linaria/core'
import { WithChildren, WithClassName, WithDataName } from 'common/types'
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  MouseEventHandler,
} from 'react'
import { Link } from 'react-router-dom'
import { Anchor } from '../Link/Anchor'
import { ButtonInner } from './ButtonInner'
import { Color, Size } from './types.common'
import { formaDJRCyrillicMicroFontFamily } from '../shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'

export const PrimaryButton: FC<
  {
    to?: string
    href?: string
    target?: string
    loading?: boolean
    size?: Size
    color?: Color
    border?: boolean
    disabled?: boolean
    withPadding?: boolean
    type?: ButtonProps['type']
    fullWidth?: boolean
    onClick?: MouseEventHandler<HTMLElement>
  } & WithChildren &
    WithDataName &
    WithClassName
> = ({
  className,
  children,
  to,
  href,
  loading,
  size = 'L',
  color = 'accent',
  border = true,
  withPadding = false,
  disabled,
  type,
  fullWidth,
  ...restProps
}) => {
  const wrapperClassNames = cx(
    border && withBorderCss,
    withPadding && withPaddingCss,
    fullWidth && fullWidthCss
  )
  const classNames = cx(
    primaryButtonCss,
    buttonSizesMapCss[size],
    colorsMap[color],
    !border && noBorderCss,
    loading ? loadingCss : statesCss,
    className
  )

  const innerElement = (
    <ButtonInner size={size} loading={loading}>
      {children}
    </ButtonInner>
  )

  const _disabled = loading || disabled

  if (to) {
    return (
      <div className={wrapperClassNames}>
        <Link to={to} className={classNames} {...restProps}>
          {innerElement}
        </Link>
      </div>
    )
  }

  if (href) {
    return (
      <div className={wrapperClassNames}>
        <Anchor href={href} className={classNames} {...restProps}>
          {innerElement}
        </Anchor>
      </div>
    )
  }

  return (
    <div className={wrapperClassNames}>
      <button
        type={type}
        className={classNames}
        disabled={_disabled}
        {...restProps}
      >
        {innerElement}
      </button>
    </div>
  )
}

type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

const primaryButtonCss = css`
  width: 100%;
  display: inline-flex;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 100px;
  font-family: ${formaDJRCyrillicMicroFontFamily};
  font-style: normal;
  color: var(--accent-primary-on-accent-primary);
  background: var(--accent-primary-default);
  font-weight: 500;
  box-shadow: 0px 0px 0px 6px
    var(--rose-opacity-hard, rgba(252, 106, 189, 0.32));
`
const statesCss = css`
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: var(--accent-primary-hover);
    }
  }

  &:active {
    background: var(--accent-primary-press);
  }

  &:disabled {
    color: var(--foreground-disabled, rgba(98, 127, 153, 0.4));
    background: var(--background-opacity-disabled, rgba(98, 127, 153, 0.24));
    /** Цвет захардкожен, т.к. дизайн добавил этот цвет после формирования палитры. На темной теме выглядит ок. */
    box-shadow: 0px 0px 0px 6px rgba(98, 127, 153, 0.096);
    cursor: default;
  }
`
const noBorderCss = css`
  box-shadow: none;
`
const withBorderCss = css`
  padding: 6px;
`
const fullWidthCss = css`
  width: 100%;
`
const buttonSizesMapCss: Record<Size, ReturnType<typeof css>> = {
  L: css`
    padding: var(--spacing-14px, 14px) var(--spacing-40px, 40px);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.32px;
  `,
  M: css`
    padding: var(--spacing-12px, 12px) var(--spacing-32px, 32px);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.32px;
  `,
  S: css`
    padding: var(--spacing-8px, 8px) var(--spacing-24px, 24px);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.28px;
  `,
}
const withPaddingCss = css`
  padding-left: var(--spacing-6px, 6px);
  padding-right: var(--spacing-6px, 6px);
`

const colorsMap: Record<Color, ReturnType<typeof css>> = {
  accent: css`
    --accent-primary-on-accent-primary: #f2f5f7;
    --accent-primary-default: #2e2a29;
    --accent-primary-hover: #5c504b;
    --accent-primary-press: #7b6b64;
    circle {
      fill: white;
    }
  `,
  white: css`
    --accent-primary-on-accent-primary: #2e2a29;
    --accent-primary-default: white;
    --accent-primary-hover: #e7deff;
    --accent-primary-press: #cebbff;
    circle {
      fill: #2e2a29;
    }
  `,
  mamba: css`
    --accent-primary-on-accent-primary: #f2f5f7;
    --accent-primary-default: #ff8640;
    --accent-primary-hover: #f07e3c;
    --accent-primary-press: #e07638;
    circle {
      fill: white;
    }
  `,
}

const loadingCss = css`
  cursor: progress;
`
