import { animated, useTransition } from '@react-spring/web'
import React, { FC } from 'react'
import { ButtonLoaderSvg } from '../svgr/ButtonLoaderSvg'
import { SIZES_MAP_PX } from './constants.common'
import { Size } from './types.common'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const ButtonLoader: FC<{
  size: Size
  show?: boolean
}> = ({ size, show }) => {
  const { rtl } = useShallowEqualSelector(({ systemReducer: { rtl } }) => ({
    rtl,
  }))

  const sizePx = SIZES_MAP_PX[size]

  const margin = rtl ? 'marginLeft' : 'marginRight'

  const transitions = useTransition(show, {
    from: { opacity: 0, width: 0, height: sizePx, [margin]: 0 },
    enter: { opacity: 1, width: sizePx, height: sizePx, [margin]: 10 },
    leave: { opacity: 0, width: 0, height: sizePx, [margin]: 0 },
    config: { duration: 200 },
  })

  return transitions(
    (springs, show) =>
      show && (
        <animated.span style={springs}>
          <ButtonLoaderSvg />
        </animated.span>
      )
  )
}
